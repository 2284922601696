export const name = 'UnauthorizedAdminAccessError';
class UnauthorizedSBARAdminAccessError extends Error {
	constructor(message: string) {
		super();
		this.name = name;
		this.message = message;

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, UnauthorizedSBARAdminAccessError);
		}
	}
}

export default UnauthorizedSBARAdminAccessError;
