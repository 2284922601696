import React from 'react';
import './Header.scss';
import { HeaderLogoImg } from './Common';
import {
	Container,
	Header as PolarisHeader,
} from '@amzn/awsui-components-react/polaris';
import Link from '@amzn/awsui-components-react/polaris/link';
import { analyticsClickEvent } from '../../analytics';
import { useAdminReportUrlContext } from '../../contexts/AdminReportUrlContextProvider';

const Header = () => {
	const { adminUrl } = useAdminReportUrlContext();

	return (
		<Container className="header-wrapper">
			<PolarisHeader className="bifrost-header">
				<Link
					data-testid="HeaderLogoLinkDesktop"
					href={adminUrl}
					children={<HeaderLogoImg isMobile={false} />}
					className="header__link_focus"
					ariaLabel="Return to administrator dashboard"
					onFollow={analyticsClickEvent(['nav', 'Logo'])}
				/>
			</PolarisHeader>
		</Container>
	);
};

export default Header;
