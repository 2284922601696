import { name as unauthorizedSBARErrorName } from '../errors/UnauthorizedSBARAdminAccessError';
import { name as unauthorizedSBAIRAdminErrorName } from '../errors/UnauthorizedAdminSBAIRAccessError';
import { name as unregisteredErrorName } from '../errors/UnregisteredUserAccessError';
import { BifrostSettings } from '../interfaces/bifrostSettings';

export const MAX_WIDTH = 1200;
export const ERROR_STRING = {
	NOT_FOUND: '404 Page Not Found',
	NOT_AUTHENTICATED: 'The user is not authenticated',
	GENERIC_HEADER: 'Something went wrong',
	GENERIC_MESSAGE: 'There was an error',
	RELOAD_MESSAGE: 'Please reload the page',
};

const ERRORS = new Map();
ERRORS.set(unauthorizedSBARErrorName, {
	headerText: "You don't have access to reports",
	descriptionText:
		'Contact your administrator to learn more or change your permissions.',
	buttonText: 'Return to Skill Builder',
	buttonUrl: (settings: BifrostSettings | undefined) =>
		settings?.skillbuilder?.doceboHomeUrl,
});

// Redirects to Homecoming Learn page.
ERRORS.set(unauthorizedSBAIRAdminErrorName, {
	headerText: "You don't have access to reports",
	descriptionText:
		'Contact your administrator to learn more or change your permissions',
	buttonText: 'Return to Skill Builder',
	buttonUrl: (settings: BifrostSettings | undefined) =>
		settings?.skillbuilder?.hcLearnerPage,
});

ERRORS.set(unregisteredErrorName, {
	headerText: `
    Thank you for your patience. Your access to Reports is currently being processed. 
    This typically takes up to 6 hours to complete.

    Please check back after 6 hours to access your reports.
	`,
	descriptionText:
		'If you are still unable to access the system after this time, please contact the Administrator. We appreciate your understanding as we ensure your data is properly set up for your reporting needs.',
	buttonText: 'Return to Skill Builder',
	buttonUrl: (settings: BifrostSettings | undefined) =>
		settings?.skillbuilderReports?.skillbuilderHcAdminDashboard,
});

const getCurrentWindowLocation = () => window.location.href;

const UNSPECIFIED_ERROR = {
	headerText: ERROR_STRING.GENERIC_HEADER,
	descriptionText: ERROR_STRING.GENERIC_MESSAGE,
	buttonText: ERROR_STRING.RELOAD_MESSAGE,
	buttonUrl: getCurrentWindowLocation,
};

export const errorCopy = (errorName: string | undefined) => {
	if (ERRORS.has(errorName)) {
		return ERRORS.get(errorName);
	}
	return UNSPECIFIED_ERROR;
};
